<template>
  <div style="text-align: center;">
    <div v-if="this.data_status.data_status == 0">
      <div class="flow-title">
        入会申请已通过协会初步审核，请下载入会申请表后再上传资料
      </div>
      <div class="box1">
        <div class="left_btn">
          <a
              href="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldFiles/scb/%E4%B8%8A%E6%B5%B7%E5%B8%82%E6%8A%A5%E5%85%B3%E5%8D%8F%E4%BC%9A%E5%85%A5%E4%BC%9A%E7%94%B3%E8%AF%B7%E8%A1%A8.zip"
          >下载入会申请表</a
          >
        </div>
        <div style="width:200px;padding:0 20px">
          <el-divider direction="horizontal"></el-divider>
        </div>
        <div class="right_btn" @click="handleOpen">上传附件</div>
      </div>
    </div>
    <div  v-else>
      <div v-if="this.data_status.status == 1">
        <el-image
            style="width: 213px; height: 177px;margin-bottom: 32px;"
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/applyjoinmeeting.png"
            fit="scale-down"></el-image>
        <div class="toptext" style="">
          审核中
        </div>
        <div class="mb20 bottomtext">
          入会资料已提交审核，请耐心等待协会审核！
        </div>
      </div>
      <div v-if="this.data_status.status == 2">
        <el-image
            style="width: 213px; height: 177px;margin-bottom: 32px;"
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/formCheck.png"
            fit="scale-down"></el-image>
        <div class="findings" style="">
          审核结果
        </div>
      </div>
      <div class="audit_wrap" v-if="this.data_status.status == 2">
        <div
            class="datum"
            v-for="(item, index) in this.data_status.attachment_data"
            :key="index"
        >
          <div class="name">
            <span v-if="item.attachment_type == 1">入会申请表</span>
            <span v-if="item.attachment_type == 2">法人身份证复印件</span>
            <span v-if="item.attachment_type == 3">营业执照</span>
          </div>
          <div class="status">
            <span class="reviewed" v-if="item.audit_status == 0">
              <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/applymeetingawait.png"/>
              <span>
                待审核
              </span>
            </span>
            <span class="approved" v-if="item.audit_status == 1">
              <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/applymeetingsuccess.png"/>
              <span>
                审核通过
              </span>
            </span>
            <span class="refuse" v-if="item.audit_status == 2">
              <span class="refuseItem">
                <span class="refuseItem_top">
                  <img
                      src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/applymeetingrefuse.png"/>
                <span>
                  审核拒绝
                </span>
                </span>
                <span class="refuseItem_bottom">
                  <el-button size="small" class="reason" @click="clickDialog(item)" type="primary"
                             plain>拒绝原因</el-button>
                  <fileUpload
                      v-if="item.audit_status == 2"
                      :accept="fileUploadOptions.accept"
                      :limitSize="fileUploadOptions.limitSize"
                      :autoUpload="fileUploadOptions.autoUpload"
                      @uploadCompleteOSS="updateItem"
                  >
                    <el-button size="small" class="update" @click="updateSome(item)" plain>重新上传</el-button>
            </fileUpload>
                </span>
              </span>

            </span>

          </div>
        </div>
      </div>
    </div>
    <h1 class="big_title" style="margin-top:55px;margin-bottom:22px">
      入会流程
    </h1>
    <img
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/LC.png"
        alt=""
    />

    <h1 class="big_title" style="margin-top:55px;margin-bottom:22px">
      入会指南
    </h1>
    <div>
      <div v-for="(item, i) in ruhui_lc" :key="i" class="lc_item">
        <span>{{ item.tit }}</span>
        <p>{{ item.msg }}</p>
      </div>
    </div>
    <div class="footerBox">
      <div>
        <h1 class="big_title">会员权益</h1>
        <p>1、有本会选举权、被选举权和表决权</p>
        <p>2、对本会的工作提出建议，进行批评、监督</p>
        <p>3、优先得到协会的资料及提供的服务</p>
        <p>4、优先参加协会组织的有关活动</p>
      </div>
      <img
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/ship1.png"
          alt=""
      />
    </div>
    <el-dialog
        title="上传附件"
        width="900px"
        :visible.sync="dialogTableVisible"
    >
      <div class="dialogConten">
        <div class="sts_success" v-if="upSuccess">上传成功！</div>
        <div
            class="sts_error"
            v-if="
            (fileCheck && !file_1) ||
              (fileCheck && !file_2) ||
              (fileCheck && !file_3)
          "
        >
          请上传附件！
        </div>
        <div class="upLoadBox">
          <fileUpload
              :accept="fileUploadOptions.accept"
              :limitSize="fileUploadOptions.limitSize"
              :autoUpload="fileUploadOptions.autoUpload"
              @uploadCompleteOSS="uploadCompleteOSS1"
          >
            <div class="item">
              <div style="margin-bottom:13px" v-if="file_1 || fileCheck">
                <i
                    v-if="file_1"
                    class="el-icon-success"
                    style="color:#48d17b;font-size: 24px;"
                ></i>
                <i
                    v-if="fileCheck && !file_1"
                    class="el-icon-error"
                    style="color:#FA5151;font-size: 24px;"
                ></i>
              </div>
              <div>
                <i class="el-icon-upload2"></i>
                <span>选择入会申请表上传（须盖章）</span>
              </div>
            </div>
          </fileUpload>
          <fileUpload
              :accept="fileUploadOptions.accept"
              :limitSize="fileUploadOptions.limitSize"
              :autoUpload="fileUploadOptions.autoUpload"
              @uploadCompleteOSS="uploadCompleteOSS2"
          >
            <div class="item">
              <div style="margin-bottom:13px" v-if="file_2 || fileCheck">
                <i
                    v-if="file_2"
                    class="el-icon-success"
                    style="color:#48d17b;font-size: 24px;"
                ></i>
                <i
                    v-if="fileCheck && !file_2"
                    class="el-icon-error"
                    style="color:#FA5151;font-size: 24px;"
                ></i>
              </div>
              <div>
                <i class="el-icon-upload2"></i>
                <span>选择上传法人身份证复印件（须盖章）</span>
              </div>
            </div>
          </fileUpload>
          <fileUpload
              :accept="fileUploadOptions.accept"
              :limitSize="fileUploadOptions.limitSize"
              :autoUpload="fileUploadOptions.autoUpload"
              @uploadCompleteOSS="uploadCompleteOSS3"
          >
            <div class="item">
              <div style="margin-bottom:13px" v-if="file_3 || fileCheck">
                <i
                    v-if="file_3"
                    class="el-icon-success"
                    style="color:#48d17b;font-size: 24px;"
                ></i>
                <i
                    v-if="fileCheck && !file_3"
                    class="el-icon-error"
                    style="color:#FA5151;font-size: 24px;"
                ></i>
              </div>
              <div>
                <i class="el-icon-upload2"></i>
                <span>选择上传营业执照（须盖章）</span>
              </div>
            </div>
          </fileUpload>
        </div>
        <div class="btnBox">
          <el-button
              type="danger"
              size="small"
              @click="handleUpLoad"
              :loading="upLoading"
          >确认提交
          </el-button
          >
        </div>
        <p class="ticps">
          支持{{ fileUploadOptions.accept }}等格式文件，文件大小需小于10M
        </p>
        <div class="zhu">注：须上传以上三种材料，缺一不可</div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="300px">
      <div>
        <div class="openTitle">
          暂无企业信息, 请到控制台-企业设置 完善您的公司信息
        </div>
        <div class="tc open">
          完善企业信息<i class="el-icon-right cursor" @click="toConsole"></i>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible1" width="350px">
      <div>
        <div class="openTitle">您不是企业管理员，请联系企业管理员申请入会</div>
      </div>
    </el-dialog>
    <el-dialog title="拒绝原因" :visible.sync="refuseDialog" width="350px">
      <div style="padding:20px 10px;text-align:left;">
        {{ desc }}
      </div>
    </el-dialog>
  </div>
</template>
<script>
import fileUpload from "@/baseComponents/fileUpload";
import {checkBasicInfo} from "~tcb/utils";

export default {
  components: {
    fileUpload,
  },
  props: {
    data_status: {
      type: Object,
      default: {},
    },
  },
  watch: {
    data_status: {
      handler(newVal, oldVal) {
        let status = newVal.attachment_data.every((ee) => ee.audit_status == 0);
        if (status) {
          //三个文件全都是待审核状态
          newVal.status = 1;
        } else {
          //有文件是审核通过或者是审核失败
          newVal.status = 2;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      isActive: 1,
      payVisible: false,
      dialogVisible: false,
      dialogVisible1: false,
      dialogTableVisible: false,
      ruhui_lc: [
        {
          tit: "1、在线提交申请",
          msg: "在线填写入会申请表单提交，等待协会初步审核",
        },
        {
          tit: "2、上传入会申请资料",
          msg:
              "在线申请通过后，请下载入会申请表，如实并完整填写所有栏位内容后加盖公章； 营业执照复印件，法人身份证正方面复印件加盖公章； 所有文件扫描或拍照上传",
        },
        {
          tit: "3、内部审核",
          msg: "月度理事会上审核入会申请，若审核不通过，协会秘书处会单独通知",
        },
        {
          tit: "4、实地走访",
          msg:
              "对于审核通过的企业， 秘书处预约走访。走访后在会员群公示。走访和公示环节不能通过的企业秘书处单独通知",
        },
        {
          tit: "5、缴纳会费",
          msg: "按照协会收费标准缴纳会费",
        },
        {
          tit: "6、颁发证书",
          msg: "",
        },
      ],
      fileUploadOptions: {
        limitSize: 20,
        accept: ".xls,.doc,.docx,.word,.pdf,.ppt,.txt,.wps,.jpg,.png",
        autoUpload: true,
      },
      file_1: "",
      file_2: "",
      file_3: "",
      fileCheck: false,
      upSuccess: false,
      upLoading: false,
      selectItem: {},//当前上传的是哪一项
      refuseDialog: false,
      desc: ''//拒绝原因
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    toConsole() {
      this.$router.push({path: "/console/company/basic"});
    },
    // 点击上传附件按钮
    async handleOpen() {
      if (!this.USER_INFO) {
        this.$router.push({
          path: "/sign",
        });
        return;
      }
      if (!this.USER_INFO.company_bind_status) {
        this.dialogVisible = true;
        return;
      }
      if (!this.USER_INFO.is_admin) {
        this.dialogVisible1 = true;
        return;
      }
      if (this.data_status.data_status == 1) {
        this.$message.info("入会信息正在审核");
      } else {
        this.file_1 = "";
        this.file_2 = "";
        this.file_3 = "";
        this.dialogTableVisible = true;
      }
      this.upSuccess = false;
    },
    // 提交入会申请
    async handleUpLoad() {
      this.fileCheck = true;
      if (this.file_1 == "") {
        this.$message.warning("请上传入会申请表");
        return;
      }
      if (this.file_2 == "") {
        this.$message.warning("请上传法人身份证复印件");
        return;
      }
      if (this.file_3 == "") {
        this.$message.warning("请上传营业执照");
        return;
      }
      this.upLoading = true;
      //company_vip
      let arr = [
        {
          file_name: "111",
          url: this.file_1,
          file_type: "333",
          attachment_type: "1",
          audit_status: "0",
        },
        {
          file_name: "111",
          url: this.file_2,
          file_type: "333",
          attachment_type: "2",
          audit_status: "0",
        },
        {
          file_name: "111",
          url: this.file_3,
          file_type: "333",
          attachment_type: "3",
          audit_status: "0",
        },
      ];
      let params = {
        form_id: this.data_status.form_id,
        attachment_data: JSON.stringify(arr),
      };
      let res = await this.$store.dispatch(
          "API_position/updateAttachment",
          params
      );
      if (res && res.success) {
        this.$message.success("资料提交成功");
        this.data_status.data_status = 1;
        this.upLoading = false;
        this.dialogTableVisible = false;
        this.upSuccess = true;
        this.$parent.getStatus()
      }
    },
    uploadCompleteOSS1(e) {
      this.file_1 = e.url;
    },
    uploadCompleteOSS2(e) {
      this.file_2 = e.url;
    },
    uploadCompleteOSS3(e) {
      this.file_3 = e.url;
    },
    updateSome(item) {
      this.selectItem = item
    },
    async updateItem(e) {
      let url = e.url
      let data = this.data_status.attachment_data
      let arr = data.filter(ee => ee != this.selectItem)
      arr.push({
        file_name: "111",
        url: url,
        file_type: "333",
        attachment_type: this.selectItem.attachment_type,
        audit_status: "0"
      })
      let params = {}
      params.form_id = this.data_status.form_id
      params.data_status = 1
      params.attachment_data = JSON.stringify(arr)
      let res = await this.$store.dispatch("API_index/updateCompanyApplyStatus", params)
      if (res.success) {
        this.$message.success("资料提交成功");
        this.$parent.getStatus()
      }
    },
    clickDialog(item) {
      this.desc = item.desc
      this.refuseDialog = true
    }
  },
};
</script>
<style lang="less" scoped>
.flow-title {
  margin-top: 30px;
  margin-bottom: 17px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #1f292e;
  line-height: 29px;
}

.big_title {
  text-align: center;
  font-size: 21px;
  font-weight: 400;
  color: #1f292e;
  line-height: 29px;
}

.box1 {
  display: flex;
  align-items: center;
  justify-content: center;

  .left_btn {
    width: 173px;
    height: 40px;
    background: linear-gradient(180deg, #fad961 0%, #f76b1c 100%);
    border-radius: 20px;

    a {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }

  .right_btn {
    width: 132px;
    height: 40px;
    background: linear-gradient(180deg, #97aeff 0%, #2e5cfb 100%);
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}

.lc_item {
  text-align: center;
  padding-bottom: 20px;

  span {
    font-size: 16px;
    font-weight: 400;
  }

  p {
    font-size: 14px;
  }
}

.footerBox {
  display: flex;
  justify-content: center;

  .big_title {
    text-align: left;
    margin-bottom: 12px;
  }

  div {
    text-align: left;
    width: 300px;

    p {
      font-size: 14px;
      height: 32px;
      font-weight: 400;
      color: #1f292e;
      line-height: 32px;
    }
  }
}

/deep/ .el-dialog__header {
  text-align: left;
}

/deep/ .el-dialog__body {
  padding: 20px;
}

.dialogConten {
  height: auto;

  .sts_success {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #1f292e;
    line-height: 20px;
    padding-bottom: 23px;
  }

  .sts_error {
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #fa5151;
    line-height: 20px;
    padding-bottom: 23px;
  }

  .upLoadBox {
    display: flex;
    justify-content: space-around;

    .item {
      width: 271px;
      height: 97px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px dashed #dcdfe6;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-image: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/bg_zhnag.png");
      background-repeat: no-repeat;
      background-position: right;

      i {
        margin-right: 6px;
        color: #909399;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: #909399;
        line-height: 14px;
      }
    }
  }

  .btnBox {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .ticps {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #909399;
    line-height: 20px;
  }

  .zhu {
    padding-top: 10px;
    padding-bottom: 30px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #ff5155;
    line-height: 20px;
  }
}

.openTitle {
  font-weight: bold;
}

.open {
  margin: 20px 0 0;

  i {
    color: #4a5a81;
    margin-left: 5px;
    border: 1px solid #4a5a81;
    border-radius: 50%;
  }
}

.datum {
  display: flex;
  align-items: flex-start;
  margin-bottom: 60px;
  padding-left: 440px;

  .name {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    width: 160px;
    text-align: left;
  }

  .status {
    display: flex;
  }

  .refuse {
    display: flex;
    flex-direction: column;
  }


}

.mb20 {
  margin-bottom: 20px;
}

.toptext {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 17px;
}

.bottomtext {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.reviewed {
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    font-weight: 400;
    color: #FFAC00;
    margin-left: 10px;
  }
}

.approved {
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    font-weight: 400;
    color: #21B100;
    margin-left: 10px;
  }
}

.findings {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 70px;
}

.refuseItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .refuseItem_top {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    span {
      font-size: 16px;
      font-weight: 400;
      color: #E9483B;
      margin-left: 10px;
    }
  }

  .refuseItem_bottom {
    display: flex;
    align-items: center;
  }
}
.reason{
  margin-right: 10px;
}
.audit_wrap{
  padding-bottom: 84px;
}
</style>
